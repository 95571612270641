import { CheckCircleIcon, ExclamationTriangleIcon, InformationCircleIcon, XCircleIcon } from '@heroicons/react/24/solid'
import { Link } from 'react-router-dom'
import { classNames } from '../Helpers'

export default function Notice({ title, description, buttons = [], type = 'info', className }) {

    let colors = {}
    let Icon = ''

    switch (type) {
        case 'notice':
            colors = {
                container: 'bg-yellow-50',
                icon: 'text-yellow-400',
                title: 'text-yellow-800',
                description: 'text-yellow-700',
                button: 'bg-yellow-50 text-yellow-800 hover:bg-yellow-100 focus:ring-offset-yellow-50 focus:ring-yellow-600'
            },
                Icon = ExclamationTriangleIcon
            break
        case 'warning':
            colors = {
                container: 'bg-red-50',
                icon: 'text-red-400',
                title: 'text-red-800',
                description: 'text-red-700',
                button: 'bg-red-50 text-red-800 hover:bg-red-100 focus:ring-offset-red-50 focus:ring-red-600'
            },
                Icon = XCircleIcon
            break
        case 'success':
            colors = {
                container: 'bg-green-50',
                icon: 'text-green-400',
                title: 'text-green-800',
                description: 'text-green-700',
                button: 'bg-green-50 text-green-800 hover:bg-green-100 focus:ring-offset-green-50 focus:ring-green-600'
            },
                Icon = CheckCircleIcon
            break
        case 'info':
        default:
            colors = {
                container: 'bg-blue-50',
                icon: 'text-blue-400',
                title: 'text-blue-800',
                description: 'text-blue-700',
                button: 'bg-blue-50 text-blue-800 hover:bg-blue-100 focus:ring-offset-blue-50 focus:ring-blue-600'
            },
                Icon = InformationCircleIcon
            break
    }

    return (
        <div className={classNames("rounded-md p-4", colors.container, className)}>
            <div className="flex gap-4">
                <div className="shrink-0">
                    <Icon className={classNames("h-5 w-5", colors.icon)} aria-hidden="true" />
                </div>
                <div className="flex-1">
                    {title &&
                        <h3 className={classNames("text-sm font-medium", colors.title)}>{title}</h3>
                    }
                    {description &&
                        <div className={classNames("text-sm", colors.description)}>
                            <p>{description}</p>
                        </div>
                    }
                </div>
                <div className="flex-0 justify-end">
                    <div className="-mx-2 -my-1.5 flex">
                        {buttons.map((button, i) => (
                            <Link
                                key={i}
                                to={button.href}
                                className={classNames("px-2 py-1.5 mr-3 rounded-md text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2", colors.button)}
                                onClick={button.onClick}
                            >
                                {button.label}
                            </Link>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}